import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

export const useStyle = makeStyles<Theme>(theme => ({
  logoHeader: {
    margin: 'auto',
    height: 'auto',
    maxWidth: 180,
    verticalAlign: 'middle',
    [theme.breakpoints.down('md')]: {
      maxWidth: 150,
    },
  },
}));
