/**
 *
 * MenuItems
 *
 */

import PropTypes from 'prop-types';
import { Grid, useMediaQuery } from '@mui/material';
import { Text } from '@mamacrowd/ui-kit';
import { useTheme } from '@mui/material/styles';

import LocaleToggle from 'containers/LocaleToggle';

import Link from 'basic/Link';
import HeaderLink from 'components/HeaderLink';

import User from 'utils/User';
import { trackEvent } from 'utils/trackers';

import { useStyle } from './MenuItems.style';
import { getMenuLinks } from '../HeaderLinks';
import messages from '../messages';

function MenuItems({ additionalLink }) {
  const theme = useTheme();
  const classes = useStyle();
  const isOverMD = useMediaQuery(theme.breakpoints.up('md'));
  const isUserLogged = User.isLogged();

  const handleExtraLinkClick = item => {
    trackEvent('header_extra_link_click', item.url, 'Nav');
  };

  const menuLinksJSX = getMenuLinks().map(item => (
    <Grid item key={item.translationId} className={classes.linkContainer}>
      <HeaderLink item={item} messages={messages} />
    </Grid>
  ));

  if (additionalLink && additionalLink.active) {
    menuLinksJSX.splice(
      2,
      0,
      <Grid item key={additionalLink.title} className={classes.linkContainer}>
        <Link
          to={additionalLink.url || '/'}
          callback={() => handleExtraLinkClick(additionalLink)}
        >
          <Text color="textPrimary">{additionalLink.title}</Text>
        </Link>
      </Grid>,
    );
  }

  if (
    process.env.ENABLE_MULTILANGUAGE === 'true' &&
    !isUserLogged &&
    !isOverMD
  ) {
    menuLinksJSX.push(
      <Grid item className={classes.linkContainerMultilang}>
        <LocaleToggle textColor={theme.palette.text.primary} />
      </Grid>,
    );
  }

  return (
    <Grid
      container
      alignItems={isOverMD ? 'center' : 'normal'}
      wrap="nowrap"
      columnSpacing={isOverMD ? 2 : 0}
      direction={isOverMD ? 'row' : 'column'}
      style={{ margin: 0 }}
    >
      {menuLinksJSX}
    </Grid>
  );
}

MenuItems.propTypes = {
  additionalLink: PropTypes.object,
};

export default MenuItems;
