import makeStyles from '@mui/styles/makeStyles';

export const useStyle = makeStyles(theme => ({
  '@keyframes fadeInLoader': {
    '0%': {
      opacity: 0,
    },
    '80%': {
      opacity: 0.1,
    },
    '100%': {
      opacity: 1,
    },
  },
  loaderPlacheolder: props => ({
    height: props.height + 60,
  }),
  fadeAnimated: {
    animationName: '$fadeInLoader',
    animationDuration: '0.7s',
    animationTimingFunction: 'ease-out',
  },
  fullScreen: {
    position: 'fixed',
    top: 0,
    left: 0,
    height: '100% !important',
    zIndex: 999,
    backgroundColor: `${theme.palette.common.borderColor}70`,
  },
  loader: props => ({
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    height: props.height + 60,
  }),
}));
