import makeStyles from '@mui/styles/makeStyles';

export const useStyle = makeStyles({
  dialogTitle: {
    padding: 40,
  },
  dialogContent: {
    padding: 40,
  },
  dialogActions: {
    padding: 40,
    paddingTop: 0,
  },
});
