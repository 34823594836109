/**
 *
 * WebLayout
 *
 */

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';

// import ScrollToTop from 'components/ScrollToTop';
import Box from 'components/Box';
import Header from 'containers/Header';
import Footer from 'components/Footer';
import Intercom from 'components/Intercom';
import HeaderMessage from 'containers/HeaderMessage';
import CookiesBanner from 'containers/CookiesBanner';
import CheckValidToken from 'components/CheckValidToken';
import { makeSelectUserLogged } from 'containers/App/selectors';

import { useStyle } from './WebLayout.style';

export function WebLayout({ children, userIsLogged }) {
  const classes = useStyle();

  return (
    <>
      <Header />
      <HeaderMessage />
      <Box className={classes.pageContainer}>{children}</Box>
      <Footer />
      {/* <ScrollToTop /> */}
      {userIsLogged && <CheckValidToken />}
      <CookiesBanner />
      <Intercom />
    </>
  );
}

WebLayout.propTypes = {
  children: PropTypes.any.isRequired,
  userIsLogged: PropTypes.bool.isRequired,
};

const mapStateToProps = createStructuredSelector({
  userIsLogged: makeSelectUserLogged(),
});

export function mapDispatchToProps() {
  return {};
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(WebLayout);
