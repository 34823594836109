/**
 *
 * IconButton
 *
 */

import PropTypes from 'prop-types';

import { Text } from '@mamacrowd/ui-kit';
import MuiIconButton from '@mui/material/IconButton';

import Box from 'components/Box';

import { useStyle } from './IconButton.style';

function IconButton({
  onClick,
  icon,
  text,
  disabled = false,
  textPosition = 'bottom',
  variant = 'contained',
  color = 'primary',
  size = 'large',
  ...rest
}) {
  let direction = 'column';
  let justify = 'center';

  if (textPosition === 'bottom') {
    direction = 'column';
  } else if (textPosition === 'top') {
    direction = 'column-reverse';
  } else if (textPosition === 'end') {
    direction = 'row';
    justify = 'flex-start';
  } else if (textPosition === 'start') {
    direction = 'row-reverse';
    justify = 'flex-start';
  }

  const classes = useStyle({ variant, direction, justify, disabled });

  const handleClick = e => {
    if (!disabled && onClick && typeof onClick === 'function') {
      onClick(e);
    }
  };

  return (
    <Box className={classes.iconButtonContainer}>
      {icon && (
        <MuiIconButton
          color={color}
          disabled={disabled}
          size={size || 'medium'}
          onClick={handleClick}
          {...rest}
        >
          {icon}
        </MuiIconButton>
      )}
      {text && (
        <Text
          component="span"
          color={disabled ? 'textSecondary' : color}
          className={classes.iconButtonText}
          onClick={handleClick}
        >
          {text}
        </Text>
      )}
    </Box>
  );
}

IconButton.propTypes = {
  icon: PropTypes.any,
  text: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  textPosition: PropTypes.oneOf(['top', 'bottom', 'end', 'start']),
  variant: PropTypes.oneOf(['contained', 'outlined']),
  color: PropTypes.string,
  size: PropTypes.string,
};

export default IconButton;
