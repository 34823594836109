import makeStyles from '@mui/styles/makeStyles';
import {
  MAX_WIDTH_PAGE,
  PAGE_CONTAINER_PADDING,
  PAGE_CONTAINER_MOBILE_PADDING,
} from 'utils/constants';

export const useStyle = makeStyles(theme => ({
  pageSection: props => ({
    backgroundColor: props.isHighlight
      ? theme.palette.background.primary
      : 'transparent',
  }),
  pageContainer: {
    width: '100%',
    maxWidth: MAX_WIDTH_PAGE,
    padding: PAGE_CONTAINER_PADDING,
    textAlign: 'left',
    [theme.breakpoints.down('md')]: {
      padding: PAGE_CONTAINER_MOBILE_PADDING,
    },
  },
  sectionHeaderTitle: {
    paddingBottom: 24,
  },
  sectionHeaderSubTitle: {
    paddingBottom: 24,
  },
  sectionHeaderLinkDesktop: {
    display: 'flex',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  sectionHeaderLinkMobile: {
    marginTop: 20,
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
}));
