/**
 *
 * Loader
 *
 */

import PropTypes from 'prop-types';

import { CircularProgress } from '@mui/material';

import Box from 'components/Box';

import { useStyle } from './Loader.style';

function Loader({
  variant = 'default',
  isLoading = true,
  enableFade = true,
  height = 40,
  ...rest
}) {
  const classes = useStyle({ height });

  const loaderJSX = <CircularProgress size={height} {...rest} />;

  if (isLoading) {
    if (variant === 'full-screen') {
      return (
        <Box className={`${classes.loader} ${classes.fullScreen}`}>
          {loaderJSX}
        </Box>
      );
    }
    return (
      <Box
        className={`${classes.loader} ${
          enableFade ? classes.fadeAnimated : ''
        }`}
      >
        {loaderJSX}
      </Box>
    );
  }
  return <Box className={classes.loaderPlacheolder} />;
}

Loader.propTypes = {
  variant: PropTypes.oneOf(['default', 'full-screen']),
  isLoading: PropTypes.bool.isRequired,
  height: PropTypes.number,
  enableFade: PropTypes.bool,
};

export default Loader;
