import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {
  TIMING_OPACITY_ANIMATION,
  TIMING_OPACITY_ANIMATION_MOBILE,
  TIMING_TRANSITION_ANIMATION,
  TIMING_TRANSITION_ANIMATION_MOBILE,
} from 'utils/constants';

type Props = {
  translateX?: number;
  delay?: string;
};

export const useStyle = makeStyles<Theme, Props>({
  start: props => ({
    transform: `translateX(${props.translateX || 30}px)`,
    '-ms-transform': `translateX(${props.translateX || 30}px)` /* IE 9 */,
    '-webkit-transform': `translateX(${
      props.translateX || 30
    }px)` /* Safari and Chrome */,
    '-o-transform': `translateX(${props.translateX || 30}px)` /* Opera */,
    '-moz-transform': `translateX(${props.translateX || 30}px)` /* Firefox */,
    opacity: 0,
  }),
  end: props => ({
    opacity: 1,

    // TRANSITION
    transition: `opacity ${TIMING_OPACITY_ANIMATION}s cubic-bezier(0.55, 0.06, 0.68, 0.19) 0s, transform ${TIMING_TRANSITION_ANIMATION}s ease-out 0s`,
    '-ms-transition': `opacity ${TIMING_OPACITY_ANIMATION}s cubic-bezier(0.55, 0.06, 0.68, 0.19) 0s, transform ${TIMING_TRANSITION_ANIMATION}s ease-out 0s` /* IE 9 */,
    '-webkit-transition': `opacity ${TIMING_OPACITY_ANIMATION}s cubic-bezier(0.55, 0.06, 0.68, 0.19) 0s, transform ${TIMING_TRANSITION_ANIMATION}s ease-out 0s` /* Safari and Chrome */,
    '-o-transition': `opacity ${TIMING_OPACITY_ANIMATION}s cubic-bezier(0.55, 0.06, 0.68, 0.19) 0s, transform ${TIMING_TRANSITION_ANIMATION}s ease-out 0s` /* Opera */,
    '-moz-transition': `opacity ${TIMING_OPACITY_ANIMATION}s cubic-bezier(0.55, 0.06, 0.68, 0.19) 0s, transform ${TIMING_TRANSITION_ANIMATION}s ease-out 0s` /* Firefox */,

    '@media screen and (max-width: 600px )': {
      flexDirection: 'column',
      transition: `opacity ${TIMING_OPACITY_ANIMATION_MOBILE}s cubic-bezier(0.55, 0.06, 0.68, 0.19) 0s, transform ${TIMING_TRANSITION_ANIMATION_MOBILE}s ease-out 0s`,
      '-ms-transition': `opacity ${TIMING_OPACITY_ANIMATION_MOBILE}s cubic-bezier(0.55, 0.06, 0.68, 0.19) 0s, transform ${TIMING_TRANSITION_ANIMATION_MOBILE}s ease-out 0s` /* IE 9 */,
      '-webkit-transition': `opacity ${TIMING_OPACITY_ANIMATION_MOBILE}s cubic-bezier(0.55, 0.06, 0.68, 0.19) 0s, transform ${TIMING_TRANSITION_ANIMATION_MOBILE}s ease-out 0s` /* Safari and Chrome */,
      '-o-transition': `opacity ${TIMING_OPACITY_ANIMATION_MOBILE}s cubic-bezier(0.55, 0.06, 0.68, 0.19) 0s, transform ${TIMING_TRANSITION_ANIMATION_MOBILE}s ease-out 0s` /* Opera */,
      '-moz-transition': `opacity ${TIMING_OPACITY_ANIMATION_MOBILE}s cubic-bezier(0.55, 0.06, 0.68, 0.19) 0s, transform ${TIMING_TRANSITION_ANIMATION_MOBILE}s ease-out 0s` /* Firefox */,
    },

    // TRANSFORM
    transform: 'translateX(0)',
    '-ms-transform': `translateX(0)` /* IE 9 */,
    '-webkit-transform': `translateX(0)` /* Safari and Chrome */,
    '-o-transform': `translateX(0)` /* Opera */,
    '-moz-transform': `translateX(0)` /* Firefox */,

    // DELAY
    transitionDelay: props.delay || '0s',
    '-ms-transition-delay': props.delay || '0s' /* IE 9 */,
    '-webkit-transition-delay': props.delay || '0s' /* Safari and Chrome */,
    '-o-transition-delay': props.delay || '0s' /* Opera */,
    '-moz-transition-delay': props.delay || '0s' /* Firefox */,
  }),
  animatedContainer: {
    display: 'inherit',
    width: '100%',
    alignItems: 'inherit',
    justifyContent: 'inherit',
    height: '100%',
  },
});
