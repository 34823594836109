/**
 *
 * Header
 *
 */

import { memo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';

import Close from '@mui/icons-material/Close';
import Menu from '@mui/icons-material/Menu';
import {
  Menu as MUIMenu,
  AppBar,
  Drawer,
  IconButton,
  Slide,
  Toolbar,
  useMediaQuery,
  useScrollTrigger,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

import Box from 'components/Box';
import Logo from 'components/Logo';
import LocaleToggle from 'containers/LocaleToggle';
import UserButton from 'components/UserButton';
import UserMenu from 'components/UserMenu';

import User from 'utils/User';

import { loadAdditionalHeader } from './actions';
import { makeSelectHeaderAdditionalLink, makeSelectCalled } from './selectors';
import reducer from './reducer';
import saga from './saga';
import MenuItems from './MenuItems';
import { useStyle } from './Header.style';

function HideOnScroll({ children }) {
  const trigger = useScrollTrigger();

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

function Header({ additionalLink, loadAdditionalLink, callToExtraLinkDone }) {
  useInjectReducer({ key: 'header', reducer });
  useInjectSaga({ key: 'header', saga });

  const [hamburgerIsOpened, setHamburgerOpened] = useState(false);
  const [accountIsOpened, setAccountIsOpened] = useState(false);
  const [drawerAnchor, setDrawerAnchor] = useState('left');
  const [menuAnchor, setMenuAnchor] = useState(null);

  const theme = useTheme();
  const classes = useStyle({ hamburgerIsOpened, accountIsOpened });
  const isOverMD = useMediaQuery(theme.breakpoints.up('md'));
  const isUserLogged = User.isLogged();
  const { isPowerUser, firstName } = User.getUser() || {};

  useEffect(() => {
    if (!additionalLink && loadAdditionalLink && !callToExtraLinkDone) {
      loadAdditionalLink();
    }
  }, [loadAdditionalLink, additionalLink, callToExtraLinkDone]);

  useEffect(() => {
    if (hamburgerIsOpened || accountIsOpened) {
      document.body.style.position = 'fixed';
    } else {
      document.body.style.position = 'initial';
    }
  }, [hamburgerIsOpened, accountIsOpened]);

  const handleUserMenu = event => {
    if (isOverMD) {
      setMenuAnchor(event.currentTarget);
    } else {
      setMenuAnchor(null);
      setAccountIsOpened(!accountIsOpened);
      setHamburgerOpened(false);
      setDrawerAnchor('right');
    }
  };

  const handleHamburgerClick = () => {
    setMenuAnchor(null);
    setHamburgerOpened(!hamburgerIsOpened);
    setAccountIsOpened(false);
    setDrawerAnchor('left');
  };

  const handleClose = () => {
    setHamburgerOpened(false);
    setAccountIsOpened(false);
    setMenuAnchor(null);
  };

  useEffect(
    () => () => {
      document.body.style.position = 'initial';
    },
    [],
  );

  return (
    <Box className={classes.header}>
      <HideOnScroll>
        <AppBar className={classes.headerContainer}>
          <Toolbar className={classes.headerItemsContainer}>
            {!isOverMD && (
              <Box style={{ marginRight: 'auto' }}>
                <IconButton
                  size="medium"
                  aria-label="menu"
                  onClick={handleHamburgerClick}
                >
                  {hamburgerIsOpened ? (
                    <Close className={classes.menuIcon} />
                  ) : (
                    <Menu className={classes.menuIcon} />
                  )}
                </IconButton>
              </Box>
            )}
            <Box>
              <Logo />
            </Box>
            {isOverMD && (
              <>
                <Box style={{ marginRight: 'auto' }}>
                  <MenuItems additionalLink={additionalLink} />
                </Box>
                {/* eslint-disable prettier/prettier */
                  process.env.ENABLE_MULTILANGUAGE === 'true' && !isUserLogged && (
                    <Box>
                      <LocaleToggle
                        textLanguageBreakpoint="lg"
                        textColor={theme.palette.text.primary}
                      />
                    </Box>
                  )/* eslint-enable prettier/prettier */}
              </>
            )}
            <Box style={{ marginLeft: isOverMD ? 0 : 'auto' }}>
              <UserButton
                firstName={firstName}
                isLogged={isUserLogged}
                isPowerUser={isPowerUser}
                onClick={handleUserMenu}
              />
            </Box>
          </Toolbar>
        </AppBar>
      </HideOnScroll>
      <MUIMenu
        anchorEl={menuAnchor}
        open={Boolean(menuAnchor) && isOverMD}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        keepMounted
      >
        <UserMenu isPowerUser={isPowerUser} isLogged={isUserLogged} />
      </MUIMenu>
      <Drawer
        anchor={drawerAnchor}
        open={(hamburgerIsOpened || accountIsOpened) && !isOverMD}
        onClose={handleClose}
        PaperProps={{ style: { width: '100%' } }}
        style={{ zIndex: 2 }}
      >
        <Toolbar className={classes.header} />
        {hamburgerIsOpened && <MenuItems additionalLink={additionalLink} />}
        {accountIsOpened && (
          <UserMenu isPowerUser={isPowerUser} isLogged={isUserLogged} />
        )}
      </Drawer>
    </Box>
  );
}

Header.propTypes = {
  loadAdditionalLink: PropTypes.func.isRequired,
  additionalLink: PropTypes.object,
  callToExtraLinkDone: PropTypes.bool,
};

const mapStateToProps = createStructuredSelector({
  additionalLink: makeSelectHeaderAdditionalLink(),
  callToExtraLinkDone: makeSelectCalled(),
});

function mapDispatchToProps(dispatch) {
  return {
    loadAdditionalLink: () => {
      dispatch(loadAdditionalHeader());
    },
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, memo)(Header);
