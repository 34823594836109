/*
 * Footer Messages
 *
 * This contains all the text for the Footer component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.components.Footer';

export default defineMessages({
  // FIRST COL
  mamacrowd: {
    id: `${scope}.mamacrowd.message`,
    defaultMessage: 'Mamacrowd',
  },
  aboutUs: {
    id: `${scope}.about_us.message`,
    defaultMessage: 'About us',
  },
  press: {
    id: `${scope}.press.message`,
    defaultMessage: 'Press',
  },
  powerUser: {
    id: `${scope}.power_user.message`,
    defaultMessage: 'Power User Program',
  },
  news: {
    id: `${scope}.news.message`,
    defaultMessage: 'News',
  },
  carrers: {
    id: `${scope}.carrers.message`,
    defaultMessage: 'Carrers',
  },
  companyInformation: {
    id: `${scope}.company_information.message`,
    defaultMessage: 'Company Information',
  },
  statisticsRealEstate: {
    id: `${scope}.statistics_real_estate.message`,
    defaultMessage: 'Real Estate Statistics',
  },
  regConsob: {
    id: `${scope}.consob.message`,
    defaultMessage: 'Official Consob Register',
  },
  // SECOND COL
  crowdfundingServices: {
    id: `${scope}.crowdfunding_services.message`,
    defaultMessage: 'Crowdfunding Services',
  },
  investmentCosts: {
    id: `${scope}.investment_costs.message`,
    defaultMessage: 'Investment Costs',
  },
  riskWarning: {
    id: `${scope}.risk_warning.message`,
    defaultMessage: 'Risk Warning',
  },
  alternativeRegimeOfShares: {
    id: `${scope}.alternative_regime_of_shares.message`,
    defaultMessage:
      'Alternative regime for registration and transfer of shares',
  },
  taxBenefits: {
    id: `${scope}.tax_benefits.message`,
    defaultMessage: 'Tax Benefits',
  },
  financialDisputes: {
    id: `${scope}.financial_disputes.message`,
    defaultMessage: 'Referee for financial disputes',
  },
  cdtRegime: {
    id: `${scope}.cdt_regime.message`,
    defaultMessage: 'Dematerialization regime',
  },
  trustCompany: {
    id: `${scope}.trust_company.message`,
    defaultMessage: 'Trust company header service',
  },
  // THIRD COL
  support: {
    id: `${scope}.support.message`,
    defaultMessage: 'Support',
  },
  howToStart: {
    id: `${scope}.how_to_start.message`,
    defaultMessage: 'How to start',
  },
  faq: {
    id: `${scope}.faq.message`,
    defaultMessage: 'FAQ',
  },
  contacts: {
    id: `${scope}.contacts.message`,
    defaultMessage: 'Contacts',
  },
  handleCookies: {
    id: `${scope}.handle_cookies.message`,
    defaultMessage: 'Handle Cookies',
  },
  // FOURTH COL
  legal: {
    id: `${scope}.legal.message`,
    defaultMessage: 'Legal',
  },
  termsConditions: {
    id: `${scope}.terms_conditions.message`,
    defaultMessage: 'Terms & Conditions',
  },
  privacyPolicy: {
    id: `${scope}.privacy_policy.message`,
    defaultMessage: 'Privacy Policy',
  },
  cookiesPolicy: {
    id: `${scope}.cookies_policy.message`,
    defaultMessage: 'Cookies Policy',
  },
  recoveryPolicy: {
    id: `${scope}.recovery_policy.message`,
    defaultMessage: 'Recovery Policy',
  },
  policyOfInterest: {
    id: `${scope}.policy_of_interest.message`,
    defaultMessage: 'Policy of Interests conflict',
  },
  powerUserTermsConditions: {
    id: `${scope}.power_user_terms_conditions.message`,
    defaultMessage: 'Power User Terms & Conditions',
  },
});
