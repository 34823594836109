/**
 *
 * FormattedMessage
 *
 */

import { FormattedMessage as AppFormattedMessage } from 'react-intl';

export interface FormattedMessageProps {
  messages: object;
  messageId: string;
  values?: object;
  isHtml?: boolean;
}

export function FormattedMessage({
  messages,
  messageId,
  values,
  ...rest
}: FormattedMessageProps) {
  if (messages && messages[messageId]) {
    return (
      <AppFormattedMessage
        {...messages[messageId]}
        values={{
          b: chunks => <b>{chunks}</b>,
          i: chunks => <i>{chunks}</i>,
          u: chunks => <u>{chunks}</u>,
          ul: chunks => <ul>{chunks}</ul>,
          li: chunks => <li>{chunks}</li>,
          sup: chunks => <sup>{chunks}</sup>,
          linebreak: <br />,
          ...values,
        }}
        {...rest}
      />
    );
  }
  if (process.env.NODE_ENV === 'development') {
    return <span>[{messageId}]</span>;
  }
  return <span>{messageId}</span>;
}

export default FormattedMessage;
