/**
 *
 * ListItemCollapsable
 *
 */

import PropTypes from 'prop-types';
import { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { Text } from '@mamacrowd/ui-kit';
import { List, ListItem, ListItemText, Collapse } from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

import User from 'utils/User';

import { useStyle } from './ListItemCollapsable.style';
import SubMenuItem from './SubMenuItem';

function ListItemCollapsable({
  primaryTitle,
  subMenuItems,
  openAtStart,
  permissions,
  actions,
  link,
  onClickCallback,
}) {
  const navigate = useHistory();
  const location = useLocation();
  const { pathname } = location;

  let isActive = pathname.includes(link);
  if (link === '/console/') {
    isActive = link === pathname;
  }

  const classes = useStyle();
  const [open, setOpen] = useState(openAtStart);

  const handleClick = () => {
    if (link) {
      if (link.includes('http') || link.includes('https')) {
        window.open(link, '_blank');
      } else {
        navigate.push(link);
      }
      if (typeof onClickCallback === 'function') {
        onClickCallback();
      }
    } else {
      setOpen(!open);
    }
  };

  const showLink =
    User.hasPermission(permissions) &&
    User.canAccessToResource(permissions, actions);

  return showLink ? (
    <>
      <ListItem button onClick={handleClick} className={classes.listItem}>
        <ListItemText
          style={{ margin: 0 }}
          primary={
            <Text
              variant="body2"
              color={isActive ? 'primary' : 'textPrimary'}
              className={classes.menuItem}
            >
              {primaryTitle}
            </Text>
          }
        />
        {!link && <>{open ? <ExpandLess /> : <ExpandMore />}</>}
      </ListItem>
      {subMenuItems && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List disablePadding>
            {subMenuItems.map(subItem => (
              <SubMenuItem
                item={subItem}
                key={`subItem-${subItem.link}`}
                onClickCallback={onClickCallback}
                permissions={permissions}
              />
            ))}
          </List>
        </Collapse>
      )}
    </>
  ) : null;
}

ListItemCollapsable.propTypes = {
  primaryTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
    .isRequired,
  subMenuItems: PropTypes.array,
  openAtStart: PropTypes.bool.isRequired,
  permissions: PropTypes.oneOfType([PropTypes.array, PropTypes.string])
    .isRequired,
  link: PropTypes.string,
  onClickCallback: PropTypes.func,
  actions: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
};

export default ListItemCollapsable;
