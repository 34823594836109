/**
 *
 * CheckValidToken
 *
 */

import { Component } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { Grid } from '@mui/material';
import { Text, Link } from '@mamacrowd/ui-kit';

import Dialog from 'basic/Dialog';
import FormattedMessage from 'components/FormattedMessage';
import { loadLogout } from 'containers/App/actions';
import { makeSelectUserLogged } from 'containers/App/selectors';

import User from 'utils/User';
import composeRedirectUrl from 'utils/composeRedirectUrl';
import { LOGIN_ROUTE } from 'utils/constants';

import messages from './messages';

type CheckValidTokenProps = {
  userLogged: boolean;
  loadLogoutData: () => void;
};
type CheckValidTokenState = {
  open: boolean;
  redirectUrl: string;
};

export class CheckValidToken extends Component<
  CheckValidTokenProps,
  CheckValidTokenState
> {
  tokenInterval: ReturnType<typeof setInterval> | null = null;

  state: CheckValidTokenState = {
    // optional second annotation for better type inference
    open: false,
    redirectUrl: '',
  };

  constructor(props) {
    super(props);

    this.state = {
      open: false,
      redirectUrl: '',
    };

    this.checkIntervalNeeded = this.checkIntervalNeeded.bind(this);
    this.checkForTokenExpired = this.checkForTokenExpired.bind(this);
  }

  componentDidMount() {
    this.checkForTokenExpired();
    if (this.props.userLogged && !this.tokenInterval) {
      this.tokenInterval = this.setCheckForExpiredTokenInterval();
    }

    const url = composeRedirectUrl(LOGIN_ROUTE);
    this.setState({ redirectUrl: url });
  }

  componentWillUnmount() {
    if (this.tokenInterval) {
      clearInterval(this.tokenInterval);
      this.tokenInterval = null;
    }
  }

  componentDidUpdate(oldProps) {
    if (this.props.userLogged !== oldProps.userLogged) {
      this.checkForTokenExpired();
      this.checkIntervalNeeded(this.props.userLogged);
    }
  }

  checkIntervalNeeded(userLogged) {
    if (userLogged && !this.tokenInterval) {
      this.tokenInterval = this.setCheckForExpiredTokenInterval();
    } else if (this.tokenInterval) {
      clearInterval(this.tokenInterval);
      this.tokenInterval = null;
    }
  }

  setCheckForExpiredTokenInterval = () =>
    setInterval(() => {
      this.checkForTokenExpired();
    }, 5000);

  checkForTokenExpired() {
    if (
      !User.getTokenExpireDate() ||
      (User.getTokenExpireDate() && User.hasTokenExpired())
    ) {
      if (!this.state.open) {
        this.setState({ open: true });
      }
      this.props.loadLogoutData();
    }
  }

  render() {
    return (
      <Dialog
        open={this.state.open}
        maxWidth="sm"
        title={
          <FormattedMessage messages={messages} messageId="sessionExpired" />
        }
        onClose={null}
        onDecline={null}
        onConfirm={null}
        confirmButtonText={null}
        declineButtonText={null}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Text
              color="textPrimary"
              id="simple-modal-description"
              align="center"
            >
              <FormattedMessage messages={messages} messageId="makeLogin" />
            </Text>
          </Grid>
          <Grid item xs={12}>
            <Link to={this.state.redirectUrl} arrow>
              <FormattedMessage messages={messages} messageId="login" />
            </Link>
          </Grid>
        </Grid>
      </Dialog>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    loadLogoutData: () => dispatch(loadLogout()),
  };
}

function mapStateToProps() {
  return createStructuredSelector({
    userLogged: makeSelectUserLogged(),
  });
}

export default connect(mapStateToProps, mapDispatchToProps)(CheckValidToken);
