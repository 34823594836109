import makeStyles from '@mui/styles/makeStyles';

export const useStyle = makeStyles(theme => ({
  footerLink: {
    display: 'block',
    '&:hover': {
      textDecoration: 'none',
    },
    '&:not(:last-child)': {
      marginBottom: 10,
    },
  },
  linkText: {
    fontFamily: 'Montserrat',
    fontSize: 14,
    fontWeight: 400,
    lineHeight: 1,
    color: theme.palette.text.footerLink,
    textAlign: 'left',
    '&:hover': {
      textDecoration: 'none',
      color: theme.palette.primary.main,
      cursor: 'pointer',
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
    '&:not(:last-child)': {
      marginBottom: 10,
    },
  },
}));
