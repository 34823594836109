import LocalStorage from 'utils/localStorage';
import SessionStorage, { LAST_TOKEN_REFRESH_DATE } from 'utils/sessionStorage';
import ENUMUserPermissions from 'utils/Enums/UserPermissions';
import { isAfter, subSeconds } from 'date-fns';
// eslint-disable-next-line import/no-cycle
import { TOKEN_EXPIRED_SECONDS } from './constants';

const User = {
  init: () => LocalStorage.getItem('user') || false,

  login: userObj => {
    if (userObj) {
      const { user } = userObj;
      LocalStorage.setItem('user', userObj);
      SessionStorage.setItem(
        LAST_TOKEN_REFRESH_DATE,
        new Date(Date.now()).toISOString(),
      );
      return user;
    }
  },

  logout: () => {
    LocalStorage.removeItem('user');
    SessionStorage.remove(LAST_TOKEN_REFRESH_DATE);
  },

  isLogged: () => {
    const userItem = LocalStorage.getItem('user');
    if (userItem && userItem.token) {
      return true;
    }
    return false;
  },

  getUser: () => {
    const userItem = LocalStorage.getItem('user');
    if (userItem && userItem.user) {
      return userItem.user;
    }
    return null;
  },

  getUserProp: propsName => {
    const userItem = LocalStorage.getItem('user');
    if (userItem && userItem.user) {
      return userItem.user[propsName];
    }
    return false;
  },

  getUserLang: () => {
    const userItem = LocalStorage.getItem('user');
    if (userItem && userItem.user) {
      return userItem.user.language;
    }
    return LocalStorage.getItem('user-language') || '';
  },

  getUserToken: () => {
    const userItem = LocalStorage.getItem('user');
    if (userItem && userItem.token) {
      return userItem.token;
    }
    return '';
  },

  getUserHash: () => {
    const userItem = LocalStorage.getItem('user');
    if (userItem && userItem.userHash) {
      return userItem.userHash;
    }
    return '';
  },

  getTokenExpireDate: () => {
    const userItem = LocalStorage.getItem('user');
    if (userItem && userItem.expireDate) {
      return userItem.expireDate;
    }
    return null;
  },

  getType: () => {
    const userItem = LocalStorage.getItem('user');
    if (userItem && userItem.user) {
      return userItem.user.role.name;
    }
    return '';
  },

  hasRole: allowedRoleNames => {
    const userItem = LocalStorage.getItem('user');
    if (!userItem || !userItem.user) {
      return false;
    }
    if (!allowedRoleNames) {
      return true;
    }
    if (User.isLogged() && allowedRoleNames) {
      if (allowedRoleNames.indexOf(userItem.user.role.name) > -1) {
        return true;
      }
    }
    return false;
  },

  hasTokenExpired: () =>
    isAfter(
      new Date(),
      subSeconds(new Date(User.getTokenExpireDate()), TOKEN_EXPIRED_SECONDS),
    ),

  getUserPermissions: () => {
    const userRoles = User.getUserProp('role');
    if (
      userRoles &&
      userRoles.permissions &&
      Object.keys(userRoles.permissions).length
    ) {
      return userRoles.permissions;
    }
    return null;
  },

  hasPermission: permission => {
    const userRolesPermissions = User.getUserPermissions();
    let canAccess = false;
    if (userRolesPermissions && Object.keys(userRolesPermissions).length) {
      if (permission !== '' && typeof permission === 'string') {
        if (
          userRolesPermissions[ENUMUserPermissions.All] ||
          (userRolesPermissions[permission] &&
            userRolesPermissions[permission] !== 'S')
        ) {
          canAccess = true;
        }
        return canAccess;
      }
      return true;
    }
    return false;
  },

  canAccessToResource: (resource, action) => {
    const userPermissions = User.getUserPermissions();
    if (
      userPermissions &&
      (!action ||
        userPermissions[ENUMUserPermissions.All] === ENUMUserPermissions.All ||
        (userPermissions[resource] &&
          (userPermissions[resource] === '*' ||
            userPermissions[resource].indexOf(action) > -1)))
    ) {
      return true;
    }
    return false;
  },
};

export default User;
