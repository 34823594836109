/**
 *
 * Logo
 *
 */

import Link from 'basic/Link';
import LogoImg from 'images/mamacrowd_logo_2022.svg';
import { HOME_PAGE_ROUTE } from 'utils/constants';

import { useStyle } from './Logo.style';

function Logo() {
  const classes = useStyle();

  return (
    <Link to={HOME_PAGE_ROUTE}>
      <img className={classes.logoHeader} src={LogoImg} alt="mamacrowd-logo" />
    </Link>
  );
}

export default Logo;
