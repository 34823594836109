/**
 *
 * MessageBanner
 *
 */

import { useState, useEffect, ReactElement } from 'react';

import { Text } from '@mamacrowd/ui-kit';
import { Grid } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import Link from 'basic/Link';
import FormattedMessage from 'components/FormattedMessage';

import ErrorIcon from 'images/MessageBannerIcons/errorIcon.svg';
import WarningIcon from 'images/MessageBannerIcons/warningIcon.svg';
import SuccessIcon from 'images/MessageBannerIcons/successIcon.svg';

import { MessageBanner as IMessageBanner } from 'types/MessageBanner';

import { useStyle } from './MessageBanner.style';
import messages from './messages';

export interface MessageBannerProps {
  message: IMessageBanner;
  closeMessageBanner: (message: IMessageBanner) => void;
}

export function MessageBanner({
  message,
  closeMessageBanner,
}: MessageBannerProps) {
  const [showMessage, setShowMessage] = useState(false);
  const classes = useStyle({ type: message.type });

  useEffect(() => {
    if (message) {
      setShowMessage(true);
    }
  }, [message]);

  let iconJSX: ReactElement | null = null;

  switch (message.type) {
    case 'alert':
      iconJSX = <img src={ErrorIcon} alt={`${message.type}-icon`} />;
      break;
    case 'warning':
      iconJSX = <img src={WarningIcon} alt={`${message.type}-icon`} />;
      break;
    case 'success':
      iconJSX = <img src={SuccessIcon} alt={`${message.type}-icon`} />;
      break;
    case 'info':
      iconJSX = null;
      break;
    default:
      iconJSX = null;
      break;
  }

  return showMessage ? (
    <Grid container id={message.id} className={classes.messageBannerContainer}>
      <Grid item xs={12} className={classes.messageBannerContent}>
        <Grid container spacing={1} alignItems="center" wrap="nowrap">
          {iconJSX && <Grid item>{iconJSX}</Grid>}
          <Grid item style={{ flexGrow: 1 }}>
            <Text className={classes.messageBannerText}>{message.text}</Text>
            <Link to={message.ctaUrl} target="_blank">
              <Text component="span" className={classes.messageBannerCTA}>
                {messages[message?.ctaTitle] ? (
                  <FormattedMessage
                    messages={messages}
                    messageId={message?.ctaTitle}
                  />
                ) : (
                  message?.ctaTitle
                )}
              </Text>
            </Link>
          </Grid>
          {closeMessageBanner && !message.alwaysOn && (
            <Grid item>
              <CloseIcon
                className={classes.closeIcon}
                data-testid="banner-button"
                onClick={() => {
                  setShowMessage(false);
                  closeMessageBanner(message);
                }}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  ) : null;
}

export default MessageBanner;
