/**
 * Asynchronously loads the component for NotFoundPage
 */

import loadable from 'utils/loadable';
import Loader from 'basic/Loader';

export default loadable(() => import('./index'), {
  fallback: <Loader isLoading />,
});
