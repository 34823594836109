import makeStyles from '@mui/styles/makeStyles';

export const useStyle = makeStyles({
  container: {
    borderTop: '1px solid #E5E5E5',
    width: '100%',
    padding: '15px 0px',
    textAlign: 'center',
    marginTop: 20,
  },
  textPec: {
    fontSize: 12,
    color: '#666666',
  },
  containerLanding: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderTop: '0.5px solid #e5e5e5',
  },
  textLanding: {
    fontSize: 12,
    color: '#666666',
    padding: '10px 4%',
    textAlign: 'center',
  },
});
