/* eslint-disable react/jsx-props-no-spreading */
/**
 *
 * Link
 *
 */

import React, { isValidElement } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { Text } from '@mamacrowd/ui-kit';
import MuiLink from '@mui/material/Link';
import ArrowForwardIosRounded from '@mui/icons-material/ArrowForwardIosRounded';

import isExternalLink, { removeDomainFromLink } from 'utils/isExternalLink';

import { useStyle } from './Link.style';

export interface LinkProps extends React.HTMLAttributes<HTMLAnchorElement> {
  to?: string;
  color?: string;
  target?: string;
  forceExternal?: boolean;
  arrow?: boolean;
  onLinkClick?: React.MouseEventHandler<HTMLAnchorElement> | undefined;
  isWrapped?: boolean;
  callback?: () => void;
  children: React.ReactNode | React.ReactNode[];
}

function Link({
  to = '',
  color = '',
  target = '_self',
  forceExternal = false,
  arrow = false,
  isWrapped = false,
  callback = () => {},
  onLinkClick,
  children,
  ...rest
}: React.PropsWithChildren<LinkProps>) {
  const classes = useStyle({ color });

  const handleLinkClick = () => {
    if (callback && typeof callback === 'function') {
      callback();
    }
  };

  const childrenIsImg = (
    elements: React.ReactNode | React.ReactNode[],
  ): boolean => {
    if (elements && !Array.isArray(elements) && isValidElement(elements)) {
      return elements.type === 'img';
    }

    return false;
  };

  const optimizedLink = forceExternal ? to : removeDomainFromLink(to);

  const linkJSX =
    isWrapped || childrenIsImg(children) ? (
      <>{children}</>
    ) : (
      <Text
        variant="inherit"
        component="span"
        onClick={handleLinkClick}
        className={classes.linkText}
      >
        {children}
        {arrow && (
          <ArrowForwardIosRounded
            fontSize="inherit"
            className={classes.linkArrow}
          />
        )}
      </Text>
    );

  if (typeof onLinkClick === 'function') {
    return (
      <MuiLink
        component="a"
        className={`${classes.appLink} ${rest?.className || ''}`}
        onClick={onLinkClick}
        {...rest}
      >
        {linkJSX}
      </MuiLink>
    );
  }

  if (to) {
    return isExternalLink(optimizedLink) || forceExternal ? (
      <MuiLink
        href={optimizedLink}
        target={target}
        component="a"
        rel="noreferrer"
        className={classes.appLink}
        {...rest}
      >
        {linkJSX}
      </MuiLink>
    ) : (
      <RouterLink
        to={optimizedLink}
        className={classes.appLink}
        rel="noreferrer"
        {...rest}
      >
        {linkJSX}
      </RouterLink>
    );
  }
  return <>{linkJSX}</>;
}

export default Link;
