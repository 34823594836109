/**
 *
 * SecondaryButtonLink
 *
 */

import { useHistory } from 'react-router-dom';

import { Text } from '@mamacrowd/ui-kit';
import { Theme, useMediaQuery } from '@mui/material';

import Link from 'basic/Link';
import Button from 'basic/Button';
import FormattedMessage from 'components/FormattedMessage';

import { HeaderLinkProps } from '..';

export interface SecondaryButtonLinkProps {
  item: HeaderLinkProps['item'];
  messages: object;
}

function SecondaryButtonLink({ item, messages }: SecondaryButtonLinkProps) {
  const history = useHistory();
  const isDownSM = useMediaQuery<Theme>(theme => theme.breakpoints.down('md'));

  const handleClick = () => {
    if (item.link) {
      history.push(item.link);
    }
  };

  return isDownSM ? (
    <Link to={item.link}>
      <Text color="textPrimary">
        <FormattedMessage messages={messages} messageId={item.translationId} />
      </Text>
    </Link>
  ) : (
    <Button
      variant="secondary"
      onClick={handleClick}
      text={
        <FormattedMessage messages={messages} messageId={item.translationId} />
      }
    />
  );
}

export default SecondaryButtonLink;
