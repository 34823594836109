import makeStyles from '@mui/styles/makeStyles';

export const useStyle = makeStyles(theme => ({
  footerSectionTitle: {
    textTransform: 'capitalize',
    fontSize: 19,
    lineHeight: '20px',
    letterSpacing: '0.05px',
    fontWeight: 700,
    fontFamily: 'Montserrat',
    marginBottom: 10,
  },
  linkText: {
    fontFamily: 'Montserrat',
    fontSize: 14,
    fontWeight: 400,
    lineHeight: 1,
    color: theme.palette.text.footerLink,
    textAlign: 'left',
    '&:hover': {
      textDecoration: 'none',
      color: theme.palette.primary.main,
      cursor: 'pointer',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    '&:not(:last-child)': {
      marginBottom: 10,
    },
  },
}));
