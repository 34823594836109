/**
 *
 * Footer
 *
 */

import { Grid } from '@mui/material';

import LocaleToggle from 'containers/LocaleToggle';

import SocialMedia from 'components/SocialMedia';
import Box from 'components/Box';
import FormattedMessage from 'components/FormattedMessage';

import {
  mamacrowdSection,
  crowdfundingServicesSection,
  supportSection,
  legalSection,
} from './FooterLinkList';

import FooterSectionDesktop from './FooterSectionDesktop';
import FooterLastSection from './FooterLastSection';
import { useStyle } from './Footer.style';
import messages from './messages';

function Footer() {
  const classes = useStyle();

  return (
    <Box className={classes.footer}>
      <Box className={classes.footerSectionsContainer}>
        <Box className={classes.greenSquareBox} />
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <FooterSectionDesktop
              links={mamacrowdSection.links}
              title={
                <FormattedMessage
                  messages={messages}
                  messageId={mamacrowdSection.translationIdTitle}
                />
              }
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <FooterSectionDesktop
              links={crowdfundingServicesSection.links}
              title={
                <FormattedMessage
                  messages={messages}
                  messageId={crowdfundingServicesSection.translationIdTitle}
                />
              }
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <FooterSectionDesktop
              links={legalSection.links}
              title={
                <FormattedMessage
                  messages={messages}
                  messageId={legalSection.translationIdTitle}
                />
              }
            />
          </Grid>
          <Grid item xs={12} sm={6} md={2}>
            <FooterSectionDesktop
              links={supportSection.links}
              title={
                <FormattedMessage
                  messages={messages}
                  messageId={supportSection.translationIdTitle}
                />
              }
            />
          </Grid>
          {/* Social media */}
          <Grid item xs={12}>
            <Grid
              container
              spacing={2}
              alignItems="center"
              justifyContent="end"
            >
              {process.env.ENABLE_MULTILANGUAGE === 'true' ? (
                <Grid item>
                  <LocaleToggle />
                </Grid>
              ) : null}
              <Grid item>
                <SocialMedia />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <FooterLastSection />
      </Box>
    </Box>
  );
}

export default Footer;
