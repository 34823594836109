/**
 *
 * FooterLink
 *
 */

import { ReactNode } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Text } from '@mamacrowd/ui-kit';
import MuiLink from '@mui/material/Link';
import isExternalLink, { removeDomainFromLink } from 'utils/isExternalLink';
import { useStyle } from './FooterLink.style';

export interface FooterLinkProps {
  message: ReactNode | string;
  link: string;
}

function FooterLink({ message, link }: FooterLinkProps) {
  const classes = useStyle();
  const optimizedLink = removeDomainFromLink(link);

  return isExternalLink(optimizedLink) ? (
    <MuiLink
      href={optimizedLink}
      target="_blank"
      component="a"
      className={classes.footerLink}
      rel="noreferrer"
    >
      <Text color="textSecondary" className={classes.linkText}>
        {message}
      </Text>
    </MuiLink>
  ) : (
    <RouterLink
      to={optimizedLink}
      color="secondary"
      className={classes.footerLink}
    >
      <Text color="textSecondary" className={classes.linkText}>
        {message}
      </Text>
    </RouterLink>
  );
}

export default FooterLink;
