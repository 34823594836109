/**
 *
 * SidebarMenu
 *
 */

import PropTypes from 'prop-types';

import { Text } from '@mamacrowd/ui-kit';
import {
  List,
  useMediaQuery,
  SwipeableDrawer,
  IconButton,
  Divider,
} from '@mui/material';
import ChevronLeft from '@mui/icons-material/ChevronLeft';

import Box from 'components/Box';
import FormattedMessage from 'components/FormattedMessage';

import User from 'utils/User';
import ENUMUserPermissions from 'utils/Enums/UserPermissions';
import ENUMUserActions from 'utils/Enums/UserActions';
import {
  CONSOLE_PREFIX,
  CONSOLE_HOME_PAGE_ROUTE,
  CONSOLE_LIST_CAMPAIGNS_ROUTE,
  CONSOLE_CAMPAIGN_ROUTE,
  CONSOLE_CAMPAIGN_ORDERS_ROUTE,
  CONSOLE_CAMPAIGN_INCOMPLETE_ORDERS_ROUTE,
  CONSOLE_CAMPAIGN_INVESTORS_LIST_ROUTE,
  CONSOLE_CAMPAIGN_EARLY_BIRD_ROUTE,
  CONSOLE_CAMPAIGN_FOLLOWERS_ROUTE,
  CONSOLE_INVESTMENT_BANK_ORDER_ROUTE,
  CONSOLE_CAMPAIGN_SIM_ROUTE,
  CONSOLE_OPERATIONS_PAGE_ROUTE,
  CONSOLE_STATISTIC_CAMPAIGN_ROUTE,
  CONSOLE_LIST_COMPANIES_ROUTE,
  CONSOLE_LIST_ORDERS_ROUTE,
  CONSOLE_ORDER_ROUTE,
  CONSOLE_LIST_INCOMPLETE_ORDERS_ROUTE,
  CONSOLE_LATE_PAYMENTS_ROUTE,
  CONSOLE_BILLING_ROUTE,
  CONSOLE_LIST_USERS_ROUTE,
  CONSOLE_CUSTOM_EMAIL_ROUTE,
  CONSOLE_CUSTOM_MKT_EMAIL_ROUTE,
  CONSOLE_STATISTICS_ROUTE,
  CONSOLE_LIST_COMPETITORS_ROUTE,
  CONSOLE_LIST_MESSAGES_ROUTE,
  CONSOLE_LIST_BANNERS_ROUTE,
  CONSOLE_LIST_ARTICLES_ROUTE,
  CONSOLE_LIST_PARTNERS_ROUTE,
  CONSOLE_LIST_TESTIMONIALS_ROUTE,
  CONSOLE_LIST_PAGES_ROUTE,
  CONSOLE_LIST_PRESS_ROUTE,
  CONSOLE_LIST_FAQ_ROUTE,
  CONSOLE_LIST_SETTINGS_ROUTE,
  CONSOLE_LIST_INDUSTRIES_ROUTE,
  CONSOLE_URL_CHECKER_ROUTE,
  CONSOLE_MEDIA_PAGE_ROUTE,
  CONSOLE_GUIDES_PAGE_ROUTE,
  CONSOLE_SWAGGER_LINK,
  CONSOLE_LIST_CAMPAIGN_APPLICATION_ROUTE,
  CONSOLE_EMAIL_SERVICE_STATUS_ROUTE,
  CONSOLE_COMPANY_MONITORING_ROUTE,
  CONSOLE_CHAMBER_DOC_PAGE_ROUTE,
  STYLE_GUIDE_ROUTE,
  CONSOLE_CAMPAIGN_BILLING_ROUTE,
} from 'utils/constants';

import ListItemCollapsable from 'console/components/ListItemCollapsable';

import pkg from '../../../../package.json';
import { useStyle } from './SidebarMenu.style';
import messages from './messages';

function SidebarMenu({ showSidebar, setShowSidebar }) {
  const classes = useStyle();
  const isOverSM = useMediaQuery(theme => theme.breakpoints.up('sm'));
  const routesObject = [
    {
      primaryTitle: (
        <FormattedMessage messages={messages} messageId="overview" />
      ),
      openAtStart: false,
      permissions: [],
      actions: '',
      link: `${CONSOLE_PREFIX}${CONSOLE_HOME_PAGE_ROUTE}`,
    },
    {
      primaryTitle: (
        <FormattedMessage messages={messages} messageId="campaigns" />
      ),
      openAtStart: true,
      permissions: ENUMUserPermissions.Campaign,
      actions: ENUMUserActions.List,
      link: `${CONSOLE_PREFIX}${CONSOLE_LIST_CAMPAIGNS_ROUTE}`,
      subMenuItems: [
        {
          title: <FormattedMessage messages={messages} messageId="campaign" />,
          link: `${CONSOLE_PREFIX}${CONSOLE_CAMPAIGN_ROUTE}`,
          primary: true,
          disabled: false,
          permissions: ENUMUserPermissions.Campaign,
          actions: ENUMUserActions.Read,
        },
        {
          title: (
            <FormattedMessage
              messages={messages}
              messageId="investmentOrders"
            />
          ),
          link: `${CONSOLE_PREFIX}${CONSOLE_CAMPAIGN_ORDERS_ROUTE}`,
          primary: true,
          disabled: false,
          permissions: ENUMUserPermissions.InvestmentOrder,
          actions: ENUMUserActions.List,
        },
        {
          title: (
            <FormattedMessage
              messages={messages}
              messageId="incompleteOrdersList"
            />
          ),
          link: `${CONSOLE_PREFIX}${CONSOLE_CAMPAIGN_INCOMPLETE_ORDERS_ROUTE}`,
          primary: true,
          disabled: false,
          permissions: ENUMUserPermissions.InvestmentOrder,
          actions: ENUMUserActions.List,
        },
        {
          title: (
            <FormattedMessage messages={messages} messageId="investorsList" />
          ),
          link: `${CONSOLE_PREFIX}${CONSOLE_CAMPAIGN_INVESTORS_LIST_ROUTE}`,
          primary: true,
          disabled: false,
          permissions: ENUMUserPermissions.InvestmentOrder,
          actions: ENUMUserActions.Read,
        },
        {
          title: <FormattedMessage messages={messages} messageId="earlyBird" />,
          link: `${CONSOLE_PREFIX}${CONSOLE_CAMPAIGN_EARLY_BIRD_ROUTE}`,
          primary: true,
          disabled: false,
          permissions: ENUMUserPermissions.EarlyBird,
          actions: ENUMUserActions.List,
        },
        {
          title: <FormattedMessage messages={messages} messageId="followers" />,
          link: `${CONSOLE_PREFIX}${CONSOLE_CAMPAIGN_FOLLOWERS_ROUTE}`,
          primary: true,
          disabled: false,
          permissions: ENUMUserPermissions.Follow,
          actions: ENUMUserActions.List,
        },
        {
          title: (
            <FormattedMessage messages={messages} messageId="ordersForBank" />
          ),
          link: `${CONSOLE_PREFIX}${CONSOLE_INVESTMENT_BANK_ORDER_ROUTE}`,
          primary: true,
          disabled: false,
          permissions: ENUMUserPermissions.Report,
          actions: ENUMUserActions.Read,
        },
        {
          title: <FormattedMessage messages={messages} messageId="sim" />,
          link: `${CONSOLE_PREFIX}${CONSOLE_CAMPAIGN_SIM_ROUTE}`,
          primary: true,
          disabled: false,
          permissions: ENUMUserPermissions.Sim,
          actions: ENUMUserActions.List,
        },
        {
          title: <FormattedMessage messages={messages} messageId="billing" />,
          link: `${CONSOLE_PREFIX}${CONSOLE_CAMPAIGN_BILLING_ROUTE}`,
          primary: true,
          disabled: false,
          permissions: ENUMUserPermissions.Billing,
          actions: ENUMUserActions.Read,
        },
        {
          title: <FormattedMessage messages={messages} messageId="stats" />,
          link: `${CONSOLE_PREFIX}${CONSOLE_STATISTIC_CAMPAIGN_ROUTE}`,
          primary: true,
          disabled: false,
          permissions: ENUMUserPermissions.Campaign,
          actions: ENUMUserActions.Read,
        },
        {
          title: (
            <FormattedMessage messages={messages} messageId="operations" />
          ),
          link: `${CONSOLE_PREFIX}${CONSOLE_OPERATIONS_PAGE_ROUTE}`,
          primary: true,
          disabled: false,
          permissions: ENUMUserPermissions.Notification,
          actions: ENUMUserActions.Execute,
        },
      ],
    },
    {
      primaryTitle: (
        <FormattedMessage messages={messages} messageId="companies" />
      ),
      openAtStart: true,
      permissions: ENUMUserPermissions.Company,
      link: `${CONSOLE_PREFIX}${CONSOLE_LIST_COMPANIES_ROUTE}`,
      actions: ENUMUserActions.List,
      subMenuItems: [
        {
          title: (
            <FormattedMessage
              messages={messages}
              messageId="companyMonitoring"
            />
          ),
          primary: true,
          disabled: false,
          openAtStart: false,
          permissions: ENUMUserPermissions.Company,
          link: `${CONSOLE_PREFIX}${CONSOLE_COMPANY_MONITORING_ROUTE}`,
          actions: ENUMUserActions.List,
        },
        {
          title: (
            <FormattedMessage messages={messages} messageId="chamberDoc" />
          ),
          primary: true,
          disabled: false,
          openAtStart: false,
          permissions: ENUMUserPermissions.Company,
          link: `${CONSOLE_PREFIX}${CONSOLE_CHAMBER_DOC_PAGE_ROUTE}`,
          actions: ENUMUserActions.List,
        },
      ],
    },
    {
      primaryTitle: <FormattedMessage messages={messages} messageId="orders" />,
      openAtStart: true,
      permissions: ENUMUserPermissions.InvestmentOrder,
      link: `${CONSOLE_PREFIX}${CONSOLE_LIST_ORDERS_ROUTE}`,
      actions: ENUMUserActions.Read,
      subMenuItems: [
        {
          title: <FormattedMessage messages={messages} messageId="order" />,
          link: `${CONSOLE_PREFIX}${CONSOLE_ORDER_ROUTE}`,
          primary: true,
          disabled: false,
          permissions: ENUMUserPermissions.InvestmentOrder,
          actions: ENUMUserActions.Read,
        },
        {
          title: (
            <FormattedMessage
              messages={messages}
              messageId="incompleteOrders"
            />
          ),
          link: `${CONSOLE_PREFIX}${CONSOLE_LIST_INCOMPLETE_ORDERS_ROUTE}`,
          primary: true,
          disabled: false,
          permissions: ENUMUserPermissions.InvestmentOrder,
          actions: ENUMUserActions.Read,
        },
        {
          title: (
            <FormattedMessage messages={messages} messageId="latePayments" />
          ),
          link: `${CONSOLE_PREFIX}${CONSOLE_LATE_PAYMENTS_ROUTE}`,
          primary: true,
          disabled: false,
          permissions: ENUMUserPermissions.InvestmentOrder,
          actions: ENUMUserActions.Read,
        },
      ],
    },
    {
      primaryTitle: (
        <FormattedMessage messages={messages} messageId="billing" />
      ),
      openAtStart: false,
      permissions: ENUMUserPermissions.Billing,
      link: `${CONSOLE_PREFIX}${CONSOLE_BILLING_ROUTE}`,
      actions: ENUMUserActions.List,
    },
    {
      primaryTitle: <FormattedMessage messages={messages} messageId="users" />,
      openAtStart: false,
      permissions: ENUMUserPermissions.User,
      link: `${CONSOLE_PREFIX}${CONSOLE_LIST_USERS_ROUTE}`,
      actions: ENUMUserActions.List,
    },
    {
      primaryTitle: (
        <FormattedMessage messages={messages} messageId="statistics" />
      ),
      openAtStart: false,
      permissions: ENUMUserPermissions.DataBoard,
      link: `${CONSOLE_PREFIX}${CONSOLE_STATISTICS_ROUTE}`,
      actions: ENUMUserActions.List,
    },
    {
      primaryTitle: (
        <FormattedMessage messages={messages} messageId="competitors" />
      ),
      openAtStart: false,
      permissions: ENUMUserPermissions.Competitors,
      link: `${CONSOLE_PREFIX}${CONSOLE_LIST_COMPETITORS_ROUTE}`,
      actions: ENUMUserActions.List,
    },
    {
      primaryTitle: (
        <FormattedMessage messages={messages} messageId="campaignApplication" />
      ),
      openAtStart: false,
      permissions: ENUMUserPermissions.Campaign,
      link: `${CONSOLE_PREFIX}${CONSOLE_LIST_CAMPAIGN_APPLICATION_ROUTE}`,
      actions: ENUMUserActions.List,
    },
    {
      divider: true,
    },
    {
      primaryTitle: (
        <FormattedMessage messages={messages} messageId="crmEmail" />
      ),
      openAtStart: true,
      permissions: ENUMUserPermissions.Notification,
      actions: ENUMUserActions.List,
      subMenuItems: [
        {
          title: (
            <FormattedMessage
              messages={messages}
              messageId="customNotification"
            />
          ),
          link: `${CONSOLE_PREFIX}${CONSOLE_CUSTOM_EMAIL_ROUTE}`,
          primary: true,
          disabled: false,
          permissions: ENUMUserPermissions.Notification,
          actions: ENUMUserActions.Execute,
        },
        {
          title: (
            <FormattedMessage
              messages={messages}
              messageId="customMarketingEmail"
            />
          ),
          link: `${CONSOLE_PREFIX}${CONSOLE_CUSTOM_MKT_EMAIL_ROUTE}`,
          primary: true,
          disabled: false,
          permissions: ENUMUserPermissions.Notification,
          actions: ENUMUserActions.List,
        },
        {
          title: (
            <FormattedMessage
              messages={messages}
              messageId="emailServiceStatus"
            />
          ),
          link: `${CONSOLE_PREFIX}${CONSOLE_EMAIL_SERVICE_STATUS_ROUTE}`,
          primary: true,
          disabled: false,
          permissions: ENUMUserPermissions.Setting,
          actions: ENUMUserActions.List,
        },
      ],
    },
    {
      divider: true,
    },
    {
      primaryTitle: <FormattedMessage messages={messages} messageId="cms" />,
      openAtStart: true,
      permissions: [],
      actions: '',
      subMenuItems: [
        {
          title: <FormattedMessage messages={messages} messageId="messages" />,
          primary: true,
          disabled: false,
          permissions: ENUMUserPermissions.WebMessage,
          link: `${CONSOLE_PREFIX}${CONSOLE_LIST_MESSAGES_ROUTE}`,
          actions: ENUMUserActions.List,
        },
        {
          title: <FormattedMessage messages={messages} messageId="banners" />,
          primary: true,
          disabled: false,
          permissions: ENUMUserPermissions.HpBanner,
          link: `${CONSOLE_PREFIX}${CONSOLE_LIST_BANNERS_ROUTE}`,
          actions: ENUMUserActions.List,
        },
        {
          title: <FormattedMessage messages={messages} messageId="articles" />,
          primary: true,
          disabled: false,
          permissions: ENUMUserPermissions.News,
          link: `${CONSOLE_PREFIX}${CONSOLE_LIST_ARTICLES_ROUTE}`,
          actions: ENUMUserActions.List,
        },
        {
          title: <FormattedMessage messages={messages} messageId="partners" />,
          primary: true,
          disabled: false,
          permissions: ENUMUserPermissions.Partner,
          link: `${CONSOLE_PREFIX}${CONSOLE_LIST_PARTNERS_ROUTE}`,
          actions: ENUMUserActions.List,
        },
        {
          title: (
            <FormattedMessage messages={messages} messageId="testimonials" />
          ),
          primary: true,
          disabled: false,
          permissions: ENUMUserPermissions.Testimonial,
          link: `${CONSOLE_PREFIX}${CONSOLE_LIST_TESTIMONIALS_ROUTE}`,
          actions: ENUMUserActions.List,
        },
        {
          title: <FormattedMessage messages={messages} messageId="pages" />,
          primary: true,
          disabled: false,
          permissions: ENUMUserPermissions.Page,
          link: `${CONSOLE_PREFIX}${CONSOLE_LIST_PAGES_ROUTE}`,
          actions: ENUMUserActions.List,
        },
        {
          title: <FormattedMessage messages={messages} messageId="press" />,
          primary: true,
          disabled: false,
          permissions: ENUMUserPermissions.Press,
          link: `${CONSOLE_PREFIX}${CONSOLE_LIST_PRESS_ROUTE}`,
          actions: ENUMUserActions.List,
        },
        {
          title: <FormattedMessage messages={messages} messageId="faq" />,
          primary: true,
          disabled: false,
          permissions: ENUMUserPermissions.Faq,
          link: `${CONSOLE_PREFIX}${CONSOLE_LIST_FAQ_ROUTE}`,
          actions: ENUMUserActions.List,
        },
        {
          title: <FormattedMessage messages={messages} messageId="settings" />,
          primary: true,
          disabled: false,
          permissions: ENUMUserPermissions.Setting,
          link: `${CONSOLE_PREFIX}${CONSOLE_LIST_SETTINGS_ROUTE}`,
          actions: ENUMUserActions.List,
        },
        {
          title: (
            <FormattedMessage messages={messages} messageId="industries" />
          ),
          primary: true,
          disabled: false,
          permissions: ENUMUserPermissions.Industry,
          link: `${CONSOLE_PREFIX}${CONSOLE_LIST_INDUSTRIES_ROUTE}`,
          actions: ENUMUserActions.List,
        },
        {
          title: (
            <FormattedMessage messages={messages} messageId="urlChecker" />
          ),
          primary: true,
          disabled: false,
          permissions: ENUMUserPermissions.ErrorLogger,
          link: `${CONSOLE_PREFIX}${CONSOLE_URL_CHECKER_ROUTE}`,
          actions: ENUMUserActions.List,
        },
      ],
    },
    {
      divider: true,
    },
    {
      primaryTitle: <FormattedMessage messages={messages} messageId="media" />,
      openAtStart: false,
      permissions: [],
      link: `${CONSOLE_PREFIX}${CONSOLE_MEDIA_PAGE_ROUTE}`,
      actions: '',
    },
    {
      primaryTitle: <FormattedMessage messages={messages} messageId="guides" />,
      openAtStart: false,
      permissions: [],
      link: `${CONSOLE_PREFIX}${CONSOLE_GUIDES_PAGE_ROUTE}`,
      actions: '',
    },
    {
      primaryTitle: (
        <FormattedMessage messages={messages} messageId="apiDocs" />
      ),
      openAtStart: false,
      permissions: ENUMUserPermissions.ApiDocs,
      link: `${
        process.env.API_BASE_URL
      }${CONSOLE_SWAGGER_LINK}/${User.getUserToken()}`,
      actions: '',
      external: true,
    },
    {
      primaryTitle: (
        <FormattedMessage messages={messages} messageId="apiTasksDocs" />
      ),
      openAtStart: false,
      permissions: ENUMUserPermissions.ApiDocs,
      link: `${
        process.env.API_TASKS_BASE_URL
      }${CONSOLE_SWAGGER_LINK}/${User.getUserToken()}`,
      actions: '',
      external: true,
    },
    {
      primaryTitle: (
        <FormattedMessage messages={messages} messageId="apiRichdataDocs" />
      ),
      openAtStart: false,
      permissions: ENUMUserPermissions.ApiDocs,
      link: `${
        process.env.API_RICHDATA_BASE_URL
      }${CONSOLE_SWAGGER_LINK}/${User.getUserToken()}`,
      actions: '',
      external: true,
    },
    {
      primaryTitle: (
        <FormattedMessage messages={messages} messageId="styleGuide" />
      ),
      openAtStart: false,
      permissions: ENUMUserPermissions.ApiDocs,
      link: STYLE_GUIDE_ROUTE,
      actions: '',
      external: true,
    },
  ];

  const contentJSX = (
    <>
      <List
        component="nav"
        aria-labelledby="mamacrowd-console-menu"
        className={classes.list}
      >
        {routesObject &&
          routesObject.map((route, i) => {
            let key = `menu-${i}`;
            if (route.divider) {
              key = `menu-divider-${i}`;
              return <Box key={key} className={classes.separator} />;
            }
            return (
              <ListItemCollapsable
                key={key}
                primaryTitle={route.primaryTitle}
                subMenuItems={route.subMenuItems}
                openAtStart={route.openAtStart}
                permissions={route.permissions}
                actions={route.actions}
                link={route.link}
                onClickCallback={() => {
                  setShowSidebar(false);
                }}
              />
            );
          })}
      </List>
      <Box className={classes.versionNumber}>
        <Text color="textPrimary">
          <FormattedMessage
            messages={messages}
            messageId="version"
            values={{ version: pkg.version }}
          />
        </Text>
      </Box>
    </>
  );

  return isOverSM ? (
    <Box className={classes.sideBarMenuContainer}>{contentJSX}</Box>
  ) : (
    <SwipeableDrawer
      open={showSidebar}
      onClose={() => {
        setShowSidebar(false);
      }}
      onOpen={() => {
        setShowSidebar(true);
      }}
      anchor="left"
      classes={{ paper: classes.sideBarMenuContainer }}
    >
      <Box className={classes.drawerHeader}>
        <IconButton
          onClick={() => {
            setShowSidebar(false);
          }}
          size="large"
        >
          <ChevronLeft />
        </IconButton>
      </Box>
      <Divider />
      {contentJSX}
    </SwipeableDrawer>
  );
}

SidebarMenu.propTypes = {
  showSidebar: PropTypes.bool,
  setShowSidebar: PropTypes.func,
};

export default SidebarMenu;
