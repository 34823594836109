/**
 *
 * LiteLayout
 *
 */

import PropTypes from 'prop-types';
import Logo from 'components/Logo';
import Box from 'components/Box';
import CookiesBanner from 'containers/CookiesBanner';
import Intercom from 'components/Intercom';

import { useStyle } from './LiteLayout.style';

export function LiteLayout({ children }) {
  const classes = useStyle();

  return (
    <>
      <Box className={classes.headerContainer}>
        <Logo />
      </Box>
      <Box className={classes.pageContainer}>{children}</Box>
      <CookiesBanner />
      <Intercom />
    </>
  );
}

LiteLayout.propTypes = {
  children: PropTypes.any.isRequired,
};

export default LiteLayout;
