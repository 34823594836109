/**
 *
 * LogoutButton
 *
 */

import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';

import {
  Grid,
  ListItemIcon,
  ListItemText,
  MenuItem,
  useMediaQuery,
} from '@mui/material';
import { Text } from '@mamacrowd/ui-kit';
import Box from 'components/Box';
import FormattedMessage from 'components/FormattedMessage';
import { makeSelectUserLogged } from 'containers/App/selectors';
import { loadLogout } from 'containers/App/actions';

import { HOME_PAGE_ROUTE } from 'utils/constants';

import Logout from 'images/UserDropdown/logout.svg';

import messages from './messages';
import { useStyle } from './LogoutButton.style';

export function LogoutButton({ loadLogoutData, logoutCallback, isInSec }) {
  const classes = useStyle();
  const navigate = useHistory();
  const isOverMD = useMediaQuery(theme => theme.breakpoints.up('md'));

  const handleLogoutClick = () => {
    if (window.Intercom) {
      window.Intercom('shutdown');
    }
    loadLogoutData();
    navigate.push(HOME_PAGE_ROUTE);

    if (typeof logoutCallback === 'function') {
      logoutCallback();
    }
  };

  const buttonTextJSX = (
    <Text color="textPrimary" component="span">
      <FormattedMessage messages={messages} messageId="logout" />
    </Text>
  );

  return isOverMD || isInSec ? (
    <MenuItem className={classes.dropdownItem} onClick={handleLogoutClick}>
      <ListItemIcon>{<img src={Logout} alt="Logout" />}</ListItemIcon>
      <ListItemText>{buttonTextJSX}</ListItemText>
    </MenuItem>
  ) : (
    <Grid item className={classes.linkContainer}>
      <Box onClick={handleLogoutClick}>{buttonTextJSX}</Box>
    </Grid>
  );
}

LogoutButton.propTypes = {
  logoutIcon: PropTypes.node,
  loadLogoutData: PropTypes.func.isRequired,
  logoutCallback: PropTypes.func,
  isInSec: PropTypes.bool,
};

const mapStateToProps = createStructuredSelector({
  userLogged: makeSelectUserLogged(),
});

function mapDispatchToProps(dispatch) {
  return {
    loadLogoutData: () => {
      dispatch(loadLogout());
    },
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(LogoutButton);
