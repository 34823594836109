import makeStyles from '@mui/styles/makeStyles';

export const useStyle = makeStyles(theme => ({
  pageContainer: props => ({
    flexGrow: 1,
    backgroundColor: props.isPortfolio
      ? theme.palette.secondary.light
      : 'transparent',
    [theme.breakpoints.up('sm')]: {
      backgroundColor:
        props.isPortfolioDetail || props.isPortfolio
          ? theme.palette.secondary.light
          : 'transparent',
    },
  }),
}));
