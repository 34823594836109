/*
 *
 * LanguageToggle
 *
 */
import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';

import LanguageToggle from 'components/LanguageToggle';
import { makeSelectUserLogged } from 'containers/App/selectors';
import { changeLocale } from 'containers/LanguageProvider/actions';
import { makeSelectLocale } from 'containers/LanguageProvider/selectors';

import LocalStorage from 'utils/localStorage';
import { trackChangeLanguage } from 'utils/trackers';

import messages from './messages';
import { appLocales } from '../../i18n';

export function LocaleToggle({
  locale,
  onLocaleToggle,
  textLanguageBreakpoint,
  userIsLogged,
  color = '#949494',
  textColor = '#949494',
}) {
  const handleLangClick = lang => {
    trackChangeLanguage(lang, userIsLogged);
    onLocaleToggle(lang);
  };

  useEffect(() => {
    if (locale) {
      LocalStorage.setItem('user-language', locale);
    }
  }, [locale]);

  return (
    <LanguageToggle
      value={locale}
      values={appLocales}
      messages={messages}
      onToggle={handleLangClick}
      textLanguageBreakpoint={textLanguageBreakpoint}
      locale={locale}
      userIsLogged={userIsLogged}
      color={color}
      textColor={textColor}
    />
  );
}

LocaleToggle.propTypes = {
  onLocaleToggle: PropTypes.func,
  locale: PropTypes.string,
  textLanguageBreakpoint: PropTypes.string,
  userIsLogged: PropTypes.bool,
  color: PropTypes.string,
  textColor: PropTypes.string,
};

const mapStateToProps = createStructuredSelector({
  locale: makeSelectLocale(),
  userIsLogged: makeSelectUserLogged(),
});

export function mapDispatchToProps(dispatch) {
  return {
    onLocaleToggle: language => dispatch(changeLocale(language)),
    dispatch,
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(LocaleToggle);
