/**
 *
 * SubMenuItem
 *
 */

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { useHistory, useLocation } from 'react-router';

import { Text } from '@mamacrowd/ui-kit';
import { ListItem, ListItemText } from '@mui/material';

import User from 'utils/User';

import {
  makeSelectConsoleActiveCampaign,
  // makeSelectLocation,
  makeSelectConsoleActiveCompanyId,
  makeSelectConsoleActiveOrderId,
  makeSelectConsoleActiveUserId,
  makeSelectConsoleActivePartnerId,
  makeSelectConsoleActiveTestimonialId,
  makeSelectConsoleActiveArticleId,
  makeSelectConsoleActiveMessageId,
  makeSelectConsoleActiveBannerId,
  makeSelectConsoleActivePageId,
  makeSelectConsoleActivePressId,
  makeSelectConsoleActiveIndustryId,
} from 'containers/App/selectors';

import { useStyle } from './ListItemCollapsable.style';

function SubMenuItem({
  item,
  activeCampaign,
  companyId,
  orderId,
  userId,
  partnerId,
  testimonialId,
  articleId,
  messageId,
  bannerId,
  pageId,
  pressId,
  industryId,
  onClickCallback,
}) {
  const navigate = useHistory();
  const location = useLocation();
  const { pathname } = location;

  let isActive = false;
  let disabled = false;
  const section = pathname.split('/')[3];
  const lang = User.getUserProp('language');

  switch (section) {
    case 'campaigns':
      if (activeCampaign) {
        isActive =
          pathname ===
          `/${lang}${item.link.replace(':campaignId', activeCampaign.id)}`;
      }
      break;
    case 'companies':
      if (companyId) {
        isActive =
          pathname === `/${lang}${item.link.replace(':companyId', companyId)}`;
      }
      break;
    case 'orders':
      if (orderId) {
        isActive =
          pathname === `/${lang}${item.link.replace(':orderId', orderId)}`;
      }
      break;
    case 'users':
      if (userId) {
        isActive =
          pathname === `/${lang}${item.link.replace(':userId', userId)}`;
      }
      break;
    default:
      if (
        section === 'messages' ||
        section === 'banners' ||
        section === 'articles' ||
        section === 'partners' ||
        section === 'testimonial' ||
        section === 'pages' ||
        section === 'press' ||
        section === 'faq' ||
        section === 'settings' ||
        section === 'industries'
      ) {
        isActive = item.link.indexOf(section) > -1;
      } else {
        isActive = pathname === `/${lang}${item.link}`;
      }

      break;
  }

  if (item.link.includes(':campaignId')) {
    disabled = Boolean(!activeCampaign);
  }
  if (item.link.includes(':companyId')) {
    disabled = Boolean(!companyId);
  }
  if (item.link.includes(':orderId')) {
    disabled = Boolean(!orderId);
  }
  if (item.link.includes(':userId')) {
    disabled = Boolean(!userId);
  }
  if (item.link.includes(':partnerId')) {
    disabled = Boolean(!partnerId);
  }
  if (item.link.includes(':testimonialId')) {
    disabled = Boolean(!testimonialId);
  }
  if (item.link.includes(':articleId')) {
    disabled = Boolean(!articleId);
  }
  if (item.link.includes(':messageId')) {
    disabled = Boolean(!messageId);
  }
  if (item.link.includes(':bannerId')) {
    disabled = Boolean(!bannerId);
  }
  if (item.link.includes(':pageId')) {
    disabled = Boolean(!pageId);
  }
  if (item.link.includes(':pressId')) {
    disabled = Boolean(!pressId);
  }
  if (item.link.includes(':industryId')) {
    disabled = Boolean(!industryId);
  }

  const handleItemClick = () => {
    if (typeof onClickCallback === 'function') {
      onClickCallback();
    }

    if (!item.disabled) {
      if (item.link.includes(':campaignId')) {
        if (activeCampaign) {
          navigate.push(item.link.replace(':campaignId', activeCampaign.id));
        }
      } else if (item.link.includes(':companyId')) {
        if (companyId) {
          navigate.push(item.link.replace(':companyId', companyId));
        }
      } else if (item.link.includes(':orderId')) {
        if (orderId) {
          navigate.push(item.link.replace(':orderId', orderId));
        }
      } else if (item.link.includes(':userId')) {
        if (userId) {
          navigate.push(item.link.replace(':userId', userId));
        }
      } else if (item.link.includes(':partnerId')) {
        if (partnerId) {
          navigate.push(item.link.replace(':partnerId', partnerId));
        }
      } else if (item.link.includes(':testimonialId')) {
        if (testimonialId) {
          navigate.push(item.link.replace(':testimonialId', testimonialId));
        }
      } else if (item.link.includes(':articleId')) {
        if (articleId) {
          navigate.push(item.link.replace(':articleId', articleId));
        }
      } else if (item.link.includes(':messageId')) {
        if (messageId) {
          navigate.push(item.link.replace(':messageId', messageId));
        }
      } else if (item.link.includes(':bannerId')) {
        if (bannerId) {
          navigate.push(item.link.replace(':bannerId', bannerId));
        }
      } else if (item.link.includes(':pageId')) {
        if (pageId) {
          navigate.push(item.link.replace(':pageId', pageId));
        }
      } else if (item.link.includes(':pressId')) {
        if (pressId) {
          navigate.push(item.link.replace(':pressId', pressId));
        }
      } else if (item.link.includes(':industryId')) {
        if (industryId) {
          navigate.push(item.link.replace(':industryId', industryId));
        }
      } else {
        navigate.push(item.link);
      }
    }
  };

  const classes = useStyle({ disabled });

  const showLink =
    User.hasPermission(item.permissions) &&
    User.canAccessToResource(item.permissions, item.actions);

  return showLink ? (
    <ListItem
      button={!disabled}
      component="div"
      className={classes.listItem}
      onClick={handleItemClick}
    >
      <ListItemText
        disableTypography={disabled}
        style={{ margin: 0 }}
        primary={
          <Text
            variant="body2"
            className={
              item.primary ? classes.subMenuItem : classes.subMenuItemPadded
            }
            color={isActive ? 'primary' : 'textSecondary'}
          >
            {isActive ? <b>{item.title}</b> : item.title}
          </Text>
        }
      />
    </ListItem>
  ) : null;
}

SubMenuItem.propTypes = {
  item: PropTypes.object.isRequired,
  activeCampaign: PropTypes.object,
  companyId: PropTypes.string.isRequired,
  orderId: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
  partnerId: PropTypes.string.isRequired,
  testimonialId: PropTypes.string.isRequired,
  articleId: PropTypes.string.isRequired,
  messageId: PropTypes.string.isRequired,
  bannerId: PropTypes.string.isRequired,
  pageId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  pressId: PropTypes.string.isRequired,
  industryId: PropTypes.string.isRequired,
  onClickCallback: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
  activeCampaign: makeSelectConsoleActiveCampaign(),
  companyId: makeSelectConsoleActiveCompanyId(),
  orderId: makeSelectConsoleActiveOrderId(),
  userId: makeSelectConsoleActiveUserId(),
  partnerId: makeSelectConsoleActivePartnerId(),
  testimonialId: makeSelectConsoleActiveTestimonialId(),
  articleId: makeSelectConsoleActiveArticleId(),
  messageId: makeSelectConsoleActiveMessageId(),
  bannerId: makeSelectConsoleActiveBannerId(),
  pageId: makeSelectConsoleActivePageId(),
  pressId: makeSelectConsoleActivePressId(),
  industryId: makeSelectConsoleActiveIndustryId(),
  // location: makeSelectLocation(),
});

const withConnect = connect(mapStateToProps);

export default withConnect(SubMenuItem);
