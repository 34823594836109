/**
 *
 * FooterLastSection
 *
 */

import { Text } from '@mamacrowd/ui-kit';
import FormattedMessage from 'components/FormattedMessage';
import Box from 'components/Box';
import { SHARE_CAPITAL, PEC, VAT_NUMBER } from 'utils/constants';
import { FooterMessages } from 'utils/globalMessages';
import { useStyle } from './FooterLastSection.style';

export interface FooterLastSectionProps {
  isLanding?: boolean;
}

function FooterLastSection({ isLanding = false }: FooterLastSectionProps) {
  const classes = useStyle();

  return isLanding ? (
    <Box className={classes.containerLanding}>
      <Text color="textPrimary" className={classes.textLanding}>
        <FormattedMessage
          messages={FooterMessages}
          messageId="pecLanding"
          values={{
            currentYear: new Date().getFullYear(),
            vatNumber: VAT_NUMBER,
          }}
        />
      </Text>
    </Box>
  ) : (
    <Box className={classes.container}>
      <Text color="textPrimary" align="justify" className={classes.textPec}>
        <FormattedMessage messages={FooterMessages} messageId="disclaimer" />
      </Text>
      &nbsp;
      <Text color="textPrimary" className={classes.textPec}>
        <FormattedMessage
          messages={FooterMessages}
          messageId="pec"
          values={{
            currentYear: new Date().getFullYear(),
            shareCapital: SHARE_CAPITAL,
            pec: PEC,
            vatNumber: VAT_NUMBER,
          }}
        />
      </Text>
    </Box>
  );
}

export default FooterLastSection;
