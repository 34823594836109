/**
 *
 * StepBasicProfile
 *
 */

import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import isEqual from 'lodash/isEqual';
import omit from 'lodash/omit';
import { Grid } from '@mui/material';
import { Text } from '@mamacrowd/ui-kit';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';

import { isLengthBetween, isVatNumberValid } from 'utils/Validations';

import { goToNextStep } from 'containers/DonatePage/actions';

import Input from 'components/Input';
import Button from 'basic/Button';
import FormattedMessage from 'components/FormattedMessage';

import User from 'utils/User';

import {
  makeSelectStepBasicProfileGetError,
  makeSelectStepBasicProfileGetLoading,
  makeSelectStepBasicProfileSetLoading,
  makeSelectStepBasicProfileSetError,
  makeSelectStepBasicProfileBasicProfile,
} from './selectors';
import { getBasicProfile, setBasicProfile } from './actions';
import reducer from './reducer';
import saga from './saga';
import messages from './messages';

export function StepBasicProfile({
  basicProfile,
  getLoading,
  getError,
  setLoading,
  setError,
  getBasicProfileData,
  setBasicProfileData,
  goToNextStepData,
}) {
  useInjectReducer({ key: 'stepBasicProfile', reducer });
  useInjectSaga({ key: 'stepBasicProfile', saga });

  useEffect(() => {
    if (getBasicProfileData) {
      getBasicProfileData();
    }
  }, [getBasicProfileData]);

  useEffect(() => {
    if (basicProfile) {
      setBasicValue(basicProfile);
      if (basicProfile.id && basicProfile.isCreated && isValidating) {
        goToNextStepData();
        setisValidating(false);
      }
    } else {
      setFirstName(User.getUserProp('firstName') || '');
      setLastName(User.getUserProp('lastName') || '');
    }
  }, [basicProfile]);

  const setBasicValue = profile => {
    setFirstName(profile.firstName || '');
    setLastName(profile.lastName || '');
    setBusinessName(profile.businessName || '');
    setVatNumber(profile.vatNumber || '');
  };

  const [isValidating, setisValidating] = useState(false);

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [businessName, setBusinessName] = useState('');
  const [vatNumber, setVatNumber] = useState('');

  const [errorFirstName, setErrorFirstName] = useState(null);
  const [errorLastName, setErrorLastName] = useState(null);
  const [errorBusinessName, setErrorBusinessName] = useState(null);
  const [errorVatNumber, setErrorVatNumber] = useState(null);
  const [showError, setShowError] = useState(null);

  const validateFirstName = value => {
    const isValid = isLengthBetween(value, 2, 64);
    if (!isValid) {
      setErrorFirstName(
        <FormattedMessage
          messages={messages}
          messageId="firstNameErrorHelper"
        />,
      );
    }

    return isValid;
  };

  const validateLastName = value => {
    const isValid = isLengthBetween(value, 2, 64);
    if (!isValid) {
      setErrorLastName(
        <FormattedMessage
          messages={messages}
          messageId="lastNameErrorHelper"
        />,
      );
    }

    return isValid;
  };

  const validateBusinessName = value => {
    const isValid = !value || isLengthBetween(value, 3, 128, false);
    if (!isValid) {
      setErrorBusinessName(
        <FormattedMessage
          messages={messages}
          messageId="businessNameErrorHelper"
        />,
      );
    }

    return isValid;
  };

  const validateVatNumber = value => {
    const regexp = new RegExp('^[a-zA-Z]{2}');
    const isValid = !value || isVatNumberValid(value);

    if (!isValid && !regexp.test(value)) {
      setErrorVatNumber(
        <FormattedMessage
          messages={messages}
          messageId="vatNumberErrorHelperWithChar"
        />,
      );
    } else if (!isValid) {
      setErrorVatNumber(
        <FormattedMessage
          messages={messages}
          messageId="vatNumberErrorHelper"
        />,
      );
    }

    return isValid;
  };

  const handleBasicProfileSubmit = () => {
    setShowError(false);
    setisValidating(true);

    const newBasicProfile = {
      firstName,
      lastName,
      businessName: businessName || null,
      vatNumber: vatNumber || null,
    };

    const validFirstName = validateFirstName(newBasicProfile.firstName);
    const validLastName = validateLastName(newBasicProfile.lastName);
    const validBusinessName = validateBusinessName(
      newBasicProfile.businessName,
    );
    const validVatNumber = validateVatNumber(newBasicProfile.vatNumber);

    if (
      validFirstName &&
      validLastName &&
      validBusinessName &&
      validVatNumber
    ) {
      const areBasicProfileObjectEqual = isEqual(
        omit(basicProfile, ['id', 'createdAt', 'updatedAt']),
        omit(newBasicProfile),
      );

      if (basicProfile && areBasicProfileObjectEqual) {
        goToNextStepData();
        setisValidating(false);
      } else {
        setBasicProfileData(newBasicProfile);
      }
    } else {
      setShowError(true);
      setisValidating(false);
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <Input
          id="firstName"
          name="firstName"
          label={<FormattedMessage messages={messages} messageId="firstName" />}
          variant="standard"
          value={firstName}
          error={errorFirstName}
          cleanErrorOnChange={setErrorFirstName}
          validate={validateFirstName}
          onBlur={setFirstName}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Input
          id="lastName"
          name="lastName"
          label={<FormattedMessage messages={messages} messageId="lastName" />}
          variant="standard"
          value={lastName}
          error={errorLastName}
          cleanErrorOnChange={setErrorLastName}
          validate={validateLastName}
          onBlur={setLastName}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Input
          id="businessName"
          name="businessName"
          label={
            <FormattedMessage messages={messages} messageId="businessName" />
          }
          variant="standard"
          value={businessName}
          error={errorBusinessName}
          cleanErrorOnChange={setErrorBusinessName}
          validate={validateBusinessName}
          onBlur={setBusinessName}
          helperText={
            <FormattedMessage
              messages={messages}
              messageId="businessNameHelperText"
            />
          }
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Input
          id="vatNumber"
          name="vatNumber"
          label={<FormattedMessage messages={messages} messageId="vatNumber" />}
          variant="standard"
          value={vatNumber}
          error={errorVatNumber}
          cleanErrorOnChange={setErrorVatNumber}
          validate={validateVatNumber}
          onBlur={setVatNumber}
          helperText={
            <FormattedMessage
              messages={messages}
              messageId="vatNumberHelperText"
            />
          }
        />
      </Grid>
      {showError && (
        <Grid item xs={12}>
          <Text color="error">
            <FormattedMessage messages={messages} messageId="validationError" />
          </Text>
        </Grid>
      )}
      {getError && (
        <Grid item xs={12}>
          <Text color="error">
            <FormattedMessage
              messages={messages}
              messageId="errorGetBasicProfile"
            />
          </Text>
        </Grid>
      )}
      {setError && (
        <Grid item xs={12}>
          <Text color="error">
            <FormattedMessage
              messages={messages}
              messageId="errorSetBasicProfile"
            />
          </Text>
        </Grid>
      )}
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item>
            <Button
              text={
                <FormattedMessage messages={messages} messageId="nextStep" />
              }
              onClick={handleBasicProfileSubmit}
              disabled={getLoading || setLoading}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

StepBasicProfile.propTypes = {
  goToNextStepData: PropTypes.func.isRequired,
  getBasicProfileData: PropTypes.func.isRequired,
  setBasicProfileData: PropTypes.func.isRequired,
  basicProfile: PropTypes.object,
  getLoading: PropTypes.bool.isRequired,
  getError: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]).isRequired,
  setLoading: PropTypes.bool.isRequired,
  setError: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]).isRequired,
};

const mapStateToProps = createStructuredSelector({
  basicProfile: makeSelectStepBasicProfileBasicProfile(),
  getLoading: makeSelectStepBasicProfileGetLoading(),
  getError: makeSelectStepBasicProfileGetError(),
  setLoading: makeSelectStepBasicProfileSetLoading(),
  setError: makeSelectStepBasicProfileSetError(),
});

function mapDispatchToProps(dispatch) {
  return {
    getBasicProfileData: () => {
      dispatch(getBasicProfile());
    },
    setBasicProfileData: basicProfile => {
      dispatch(setBasicProfile(basicProfile));
    },
    goToNextStepData: () => {
      dispatch(goToNextStep());
    },
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(StepBasicProfile);
