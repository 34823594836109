/**
 *
 * AuthLayoutFooter
 *
 */

import { Text } from '@mamacrowd/ui-kit';

import Box from 'components/Box';
import FormattedMessage from 'components/FormattedMessage';

import { FooterMessages } from 'utils/globalMessages';
import { SHARE_CAPITAL, PEC, VAT_NUMBER } from 'utils/constants';

import { useStyle } from './AuthLayoutFooter.style';

function AuthLayoutFooter() {
  const classes = useStyle();

  return (
    <Box className={classes.footerContainer}>
      <Text color="textPrimary" className={classes.footerText}>
        <FormattedMessage
          messages={FooterMessages}
          messageId="pec"
          values={{
            currentYear: new Date().getFullYear(),
            shareCapital: SHARE_CAPITAL,
            pec: PEC,
            vatNumber: VAT_NUMBER,
          }}
        />
      </Text>
    </Box>
  );
}

AuthLayoutFooter.propTypes = {};

export default AuthLayoutFooter;
